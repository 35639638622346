import { useCallback, useState } from 'react'

import { SignInLayout } from '@alpha/components'
import { signIn } from '@alpha/core'

import { message, Divider } from 'antd'

import { EmailSent } from '../shared/EmailSent'

const SignInPage = () => {
  const [address, setAddress] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)

  const handleChangeAddress = (e) => setAddress(e.target.value)

  const handleSignInBtn = useCallback(async () => {
    if (
      [
        'hotai2@pref.ibaraki.lg.jp',
        'inoue@pestalozzi-tech.com',
        'sergei@pestalozzi-tech.com',
        'communication.brkdancer@gmail.com',
      ].includes(address) === false
    ) {
      message.warning(
        '本メールアドレスが登録されていません。管理者にお問い合わせください。',
      )
    } else {
      setIsLoading(true)
      const { protocol, host } = window.location
      const url = `${protocol}//${host}/`
      await signIn(address, url)
      setIsSent(true)
    }
  }, [address])

  return (
    <SignInLayout>
      <div className="flex-1 flex flex-col justify-center lg:h-screen bg-gray-100">
        <div className="mx-8 lg:mx-16 px-8 py-10 underline text-blue-500">
          <a
            href="https://ibaraki.upload.pestalozzi-tech.com/"
            target="_blank"
            rel="noreferrer"
          >
            茨城県内の各自治体集計フォーマットのアップロードはこちら
          </a>
        </div>
        <div className="flex flex-col mx-8 lg:mx-16 bg-white rounded-2xl px-8 py-10 shadow-lg space-y-6">
          <div className="flex flex-col space-y-2">
            {isSent ? (
              <div className="flex flex-col items-center space-y-12">
                <EmailSent />
                <div className="text-center">
                  <h1 className="text-xl font-semibold">
                    ログインメールを送信しました
                  </h1>
                  <h5 className="text-gray-400 font-bold">
                    ※
                    メールに送信したリンクをこちらのブラウザーで開いてください。
                  </h5>
                </div>
              </div>
            ) : (
              <>
                <h5 className="font-bold text-2xl">
                  茨城県児童生徒の体力・運動能力調査
                  <br />
                  集計システム「Alpha」へようこそ
                </h5>
                <p>メールアドレス</p>
                <input
                  type="email"
                  placeholder="メールアドレス"
                  className="bg-gray-50 p-2 rounded border border-gray-200"
                  value={address}
                  onChange={handleChangeAddress}
                />
                <div className="pt-4">
                  <button
                    className="bg-blue-500 hover:bg-blue-400 focus:bg-blue-400　text-white font-semibold py-2 p px-4 mb-4 rounded"
                    onClick={handleSignInBtn}
                    disabled={isLoading}
                    style={{ color: 'white' }}
                  >
                    メールにサインイン情報を送る
                  </button>
                </div>
                <div className="flex">
                  <div className="text-red-500 p-2 border-red-500 border mb-4">
                    HTML形式でメールが届きます。HTML形式メールを許可してください。
                    <br />
                    設定方法：
                    <a
                      className="underline font-semibold"
                      href="https://www.crie.co.jp/chokotech/detail/180/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.crie.co.jp/chokotech/detail/180/
                    </a>
                    <br />
                    <small>*外部リンクとなります</small>
                  </div>
                </div>
                <Divider />
                <div className="pt-4">
                  <p>
                    続行することで、Pestalozzi Technologyの
                    <a
                      className="text-blue-700 underline font-semibold"
                      href="https://www.pestalozzi-tech.com/ja/"
                    >
                      サービス利用規約
                    </a>
                    および、
                    <a
                      className="text-blue-700 underline font-semibold"
                      href="https://www.pestalozzi-tech.com/ja/about/privacy"
                    >
                      個人情報保護ポリシー
                    </a>
                    に同意します。
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex-none flex-col content-center h-24 flex">
          <p className="text-gray-700 text-semibold text-sm text-center mt-16">
            Copyright © Pestalozzi Technology {new Date().getFullYear()}
          </p>
        </div>
        <div className="justify-center hidden md:flex">
          <address className="text-center">
            <div>【問い合わせ先】</div>
            <a href="mailto:info@pestalozzi-tech.com">
              info@pestalozzi-tech.com
            </a>
            <div>担当：Alpha新体力テストの測定・集計システム担当</div>
          </address>
        </div>
      </div>
    </SignInLayout>
  )
}

export default SignInPage
