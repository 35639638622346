export type DashboardNavGraphItem = {
  key: string
  title: string
  tabIndex: number | undefined
}

export const dashboardNavGraphItems: DashboardNavGraphItem[] = [
  {
    key: 'home',
    title: '新体力テスト結果レポート',
    tabIndex: 2,
  },
  {
    key: 'reports',
    title: '新体力テスト結果レポート',
    tabIndex: undefined,
  },
  {
    key: 'reports/count',
    title: '調査人数',
    tabIndex: undefined,
  },
  {
    key: 'reports/body',
    title: '体格の平均値及び標準偏差',
    tabIndex: undefined,
  },
  {
    key: 'reports/test',
    title: '体力テスト測定項目の平均値と標準偏差',
    tabIndex: undefined,
  },
  {
    key: 'reports/test_comparison',
    title: '県平均値と全国平均値との比較',
    tabIndex: undefined,
  },
  {
    key: 'reports/test_rank',
    title: '総合評価段階別人数及びその割合',
    tabIndex: undefined,
  },
  {
    key: 'reports/test_rank_target',
    title: '総合評価段階別人数の割合の推移',
    tabIndex: undefined,
  },
  {
    key: 'reports/score_chart',
    title: 'Tスコアによる全国平均値による比較',
    tabIndex: undefined,
  },
  {
    key: 'reports/questionnaire',
    title: '児童生徒の生活状況',
    tabIndex: undefined,
  },
  {
    key: 'reports/cross_analysis',
    title: 'アンケート回答別クロス集計',
    tabIndex: undefined,
  },
  {
    key: 'reports/average_graph',
    title: '測定項目平均値の年次推移',
    tabIndex: undefined,
  },
  {
    key: 'reports/test_rank_target_graph',
    title: '総合評価段階別人数の割合の推移(グラフ)',
    tabIndex: undefined,
  },
  {
    key: 'reports/cross_analysis_graph',
    title: 'アンケート回答別クロス集計(グラフ)',
    tabIndex: undefined,
  },
  {
    key: 'reports/school_performance',
    title: '体力つくり関係表彰校一覧（体力つくり優秀賞用）',
    tabIndex: undefined,
  },
  {
    key: 'reports/school_progress',
    title: '体力つくり関係表彰校一覧（体力つくり奨励賞用）',
    tabIndex: undefined,
  },
  {
    key: 'reports/upload_list',
    title: 'CSVデータアップロードの確認',
    tabIndex: undefined,
  },
  {
    key: 'code',
    title: 'コード登録',
    tabIndex: undefined,
  },
  {
    key: 'register',
    title: 'ユーザー情報登録',
    tabIndex: undefined,
  },
  {
    key: '',
    title: 'サインイン',
    tabIndex: undefined,
  },
]
