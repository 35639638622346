var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
export var DatePicker = generatePicker(dayjsGenerateConfig);
export var TimePicker = React.forwardRef(function (props, ref) {
    return _jsx(DatePicker, __assign({}, props, { picker: "time", mode: undefined, ref: ref }), void 0);
});
DatePicker.displayName = 'DatePicker';
