import { Card } from '@alpha/components'
import { Dashboard } from '../layout/Dashboard'
import {
  ReportTestRankByGrade,
  ReportTestRankSummary,
  useReportTestRankSummaryLazyQuery,
} from '@alpha/core'
import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'

const { Option } = Select

type ReportTableDataProps = {
  data: ReportTestRankByGrade
  className: string
}

const ranks = ['A', 'B', 'C', 'D', 'E']

const ReportTableData = ({ data, className }: ReportTableDataProps) => {
  return (
    <tr className={className}>
      <td className={className}>{data.grade === 0 ? '合計' : data.grade}</td>
      <td className={className}>{data.totalCount}</td>
      {ranks.map((rank) => (
        <td className={className} key={`${data.grade}_count_${rank}`}>
          {data.countByRank![`rank${rank}`]}
        </td>
      ))}
      {ranks.map((rank) => (
        <td className={className} key={`${data.grade}_rate_${rank}`}>
          {(data.rateByRank![`rank${rank}`] * 100).toFixed(2)}%
        </td>
      ))}
    </tr>
  )
}

const styleForReport = {
  fontSize: 12,
  width: 64,
}

const TableHeader = ({ className }: { className: string }) => {
  return (
    <thead>
      <tr className={className}>
        <th
          className={className}
          style={{ fontSize: 12, width: 128 }}
          rowSpan={2}
        >
          校種
        </th>
        <th className={className} style={styleForReport} rowSpan={2}>
          学年
        </th>
        <th className={className} style={styleForReport} rowSpan={2}>
          実施人数
        </th>
        <th className={className} style={styleForReport} colSpan={5}>
          総合評価段階別人数
        </th>
        <th className={className} style={styleForReport} colSpan={5}>
          総合評価段階別割合
        </th>
      </tr>
      <tr className={className}>
        <th className={className} style={styleForReport}>
          A
        </th>
        <th className={className} style={styleForReport}>
          B
        </th>
        <th className={className} style={styleForReport}>
          C
        </th>
        <th className={className} style={styleForReport}>
          D
        </th>
        <th className={className} style={styleForReport}>
          E
        </th>
        <th className={className} style={styleForReport}>
          A
        </th>
        <th className={className} style={styleForReport}>
          B
        </th>
        <th className={className} style={styleForReport}>
          C
        </th>
        <th className={className} style={styleForReport}>
          D
        </th>
        <th className={className} style={styleForReport}>
          E
        </th>
      </tr>
    </thead>
  )
}

type TableBodyProps = {
  className: string
  data: ReportTestRankSummary
}

const TableBody = ({ className, data }: TableBodyProps) => {
  const {
    elementarySchoolReport,
    juniorHighSchoolReport,
    allDayHighSchoolReport,
    fixTimeHighSchoolReport,
  } = data
  if (
    !elementarySchoolReport?.reports ||
    !juniorHighSchoolReport?.reports ||
    !allDayHighSchoolReport?.reports ||
    !fixTimeHighSchoolReport?.reports
  ) {
    return null
  }
  return (
    <tbody>
      <tr>
        <th
          rowSpan={elementarySchoolReport.reports.length + 1}
          className={className}
        >
          小学校
        </th>
      </tr>
      {elementarySchoolReport.reports.map((data) => (
        <ReportTableData className={className} data={data} key={data.grade} />
      ))}
      <tr>
        <th
          rowSpan={juniorHighSchoolReport.reports.length + 1}
          className={className}
        >
          中学校
        </th>
      </tr>
      {juniorHighSchoolReport.reports.map((data) => (
        <ReportTableData className={className} data={data} key={data.grade} />
      ))}
      <tr>
        <th
          rowSpan={allDayHighSchoolReport.reports.length + 1}
          className={className}
        >
          高校
        </th>
      </tr>
      {allDayHighSchoolReport.reports.map((data) => (
        <ReportTableData className={className} data={data} key={data.grade} />
      ))}
      <tr>
        <th
          rowSpan={fixTimeHighSchoolReport.reports.length + 1}
          className={className}
        >
          高校(定時制)
        </th>
      </tr>
      {fixTimeHighSchoolReport.reports.map((data) => (
        <ReportTableData className={className} data={data} key={data.grade} />
      ))}
    </tbody>
  )
}

type TableProps = {
  prefectureCode: number
  year: number
}

const makeReportTestRankByGrade = (): ReportTestRankByGrade => {
  return {
    grade: 0,
    totalCount: 0,
    countByRank: {
      rankA: 0,
      rankB: 0,
      rankC: 0,
      rankD: 0,
      rankE: 0,
    },
    rateByRank: {
      rankA: 0,
      rankB: 0,
      rankC: 0,
      rankD: 0,
      rankE: 0,
    },
  }
}

const ReportTestTable = ({ prefectureCode, year }: TableProps) => {
  const [getReportForMale, { data: dataForMale, loading: loadingForMale }] =
    useReportTestRankSummaryLazyQuery({
      fetchPolicy: 'network-only',
    })
  const [
    getReportForFemale,
    { data: dataForFemale, loading: loadingForFemale },
  ] = useReportTestRankSummaryLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [dataForTotal, setDataForTotal] = useState<ReportTestRankSummary>()
  useEffect(() => {
    if (prefectureCode === 0) return
    getReportForMale({
      variables: {
        input: {
          prefectureCode,
          year,
          gender: 1,
        },
      },
    })
    getReportForFemale({
      variables: {
        input: {
          prefectureCode,
          year,
          gender: 2,
        },
      },
    })
  }, [prefectureCode, year, getReportForMale, getReportForFemale])

  useEffect(() => {
    if (!dataForMale || !dataForFemale) return
    const totalData: ReportTestRankSummary = {
      elementarySchoolReport: {
        reports: [makeReportTestRankByGrade()],
      },
      juniorHighSchoolReport: {
        reports: [makeReportTestRankByGrade()],
      },
      allDayHighSchoolReport: {
        reports: [makeReportTestRankByGrade()],
      },
      fixTimeHighSchoolReport: {
        reports: [makeReportTestRankByGrade()],
      },
    }
    const reports = [dataForMale, dataForFemale]
    const schools = [
      'elementarySchoolReport',
      'juniorHighSchoolReport',
      'allDayHighSchoolReport',
      'fixTimeHighSchoolReport',
    ]
    reports.forEach((d) => {
      schools.forEach((s) => {
        if (!d.reportTestRankSummary?.data) return
        const targetData = d.reportTestRankSummary?.data[s].reports
        const destData = totalData[s].reports[0]
        targetData.forEach((t) => {
          destData.totalCount += t.totalCount
          ranks.forEach((rank) => {
            destData.countByRank[`rank${rank}`] += t.countByRank[`rank${rank}`]
          })
        })
        ranks.forEach((rank) => {
          destData.rateByRank[`rank${rank}`] =
            destData.countByRank[`rank${rank}`] / destData.totalCount
        })
      })
    })
    setDataForTotal(totalData)
  }, [dataForMale, dataForFemale, setDataForTotal])

  if (loadingForMale || loadingForFemale) {
    return <Loading />
  }

  if (
    !dataForMale?.reportTestRankSummary?.data ||
    !dataForFemale?.reportTestRankSummary?.data
  ) {
    return <NoData />
  }
  if (!dataForTotal) {
    return null
  }
  const maleData = dataForMale.reportTestRankSummary.data
  const femaleData = dataForFemale.reportTestRankSummary.data

  return (
    <>
      <div>男子</div>
      <table className="table border-collapse">
        <TableHeader className="table-border-male" />
        <TableBody className="table-border-male" data={maleData} />
      </table>
      <div className="pt-4" style={{ pageBreakBefore: 'always' }}>
        女子
      </div>
      <table className="table border-collapse">
        <TableHeader className="table-border-female" />
        <TableBody className="table-border-female" data={femaleData} />
      </table>
      <div className="pt-4" style={{ pageBreakBefore: 'always' }}>
        合計
      </div>
      <table className="table border-collapse">
        <TableHeader className="table-border-total" />
        <TableBody className="table-border-total" data={dataForTotal} />
      </table>
    </>
  )
}

const ReportTestRankPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const prefectureCode = 8
  const [year, setYear] = useState(2021)

  return (
    <Dashboard
      navbar={
        <div className="theme-nav-title">総合評価段階別人数及びその割合</div>
      }
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="print:hidden">
        <Card>
          <div className="space-y-4 space-x-4 pb-8">
            <Select value={year} onChange={(v) => setYear(v)}>
              <Option value={2010}>2010 ({makeYearName(2010)})</Option>
              <Option value={2011}>2011 ({makeYearName(2011)})</Option>
              <Option value={2012}>2012 ({makeYearName(2012)})</Option>
              <Option value={2013}>2013 ({makeYearName(2013)})</Option>
              <Option value={2014}>2014 ({makeYearName(2014)})</Option>
              <Option value={2015}>2015 ({makeYearName(2015)})</Option>
              <Option value={2016}>2016 ({makeYearName(2016)})</Option>
              <Option value={2017}>2017 ({makeYearName(2017)})</Option>
              <Option value={2018}>2018 ({makeYearName(2018)})</Option>
              <Option value={2019}>2019 ({makeYearName(2019)})</Option>
              <Option value={2020}>2020 ({makeYearName(2020)})</Option>
              <Option value={2021}>2021 ({makeYearName(2021)})</Option>
            </Select>
          </div>

          <ReportTestTable prefectureCode={prefectureCode} year={year} />
        </Card>
      </div>
      <div className="hidden print:block">
        <div>
          {`${makeYearName(year)}`}
          年度茨城県児童生徒の体力・運動能力調査結果
        </div>
        <div>総合評価段階別人数及びその割合</div>
        <ReportTestTable prefectureCode={prefectureCode} year={year} />
      </div>
    </Dashboard>
  )
}

export default ReportTestRankPage
