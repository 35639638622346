var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var SignInLayout = function (_a) {
    var children = _a.children;
    return (_jsx("div", __assign({ className: "flex flex-row h-screen" }, { children: _jsxs("div", __assign({ className: "flex-1 lg:flex-1 flex flex-col content-center bg-gray-100" }, { children: [_jsxs("div", __assign({ className: "flex-none flex flex-row space-x-4 py-8 px-4" }, { children: [_jsx("img", { className: "w-8 h-8", src: "https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/logo.png", alt: "logo" }, void 0),
                        _jsx("div", __assign({ className: "text-black font-semibold text-2xl" }, { children: "Alpha" }), void 0)] }), void 0),
                _jsx("div", { children: children }, void 0),
                _jsx("div", __assign({ className: "mx-auto mt-8" }, { children: _jsx("img", { className: "w-64", src: "https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/isms.jpg", alt: "isms" }, void 0) }), void 0)] }), void 0) }), void 0));
};
