import { useState, useEffect } from 'react'

import moment from 'moment'

import { Select, Table } from 'antd'
import { ColumnType } from 'antd/es/table'

import { Card } from '@alpha/components'

import { Dashboard } from '../layout/Dashboard'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { ReportSchoolEntry, useReportUploadListLazyQuery } from '@alpha/core'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'

const { Option } = Select

const tableColumns: ColumnType<ReportSchoolEntry>[] = [
  {
    title: '学校コード',
    dataIndex: 'prefectureSchoolCode',
    key: 'prefectureSchoolCode',
    sorter: (a, b) => +a.prefectureSchoolCode! - +b.prefectureSchoolCode!,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: '学校名',
    dataIndex: 'schoolName',
    key: 'schoolName',
  },
  {
    title: '生徒数',
    dataIndex: 'studentCount',
    key: 'studentCount',
    sorter: (a, b) => a.studentCount! - b.studentCount!,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: '自治体',
    dataIndex: 'cityName',
    key: 'cityName',
  },
  {
    title: 'アップロード者',
    dataIndex: 'organizationName',
    key: 'organizationName',
  },
  {
    title: 'アップロード日時',
    dataIndex: 'uploadedAt',
    key: 'uploadedAt',
    sorter: (a, b) =>
      new Date(a.uploadedAt!).getTime() - new Date(b.uploadedAt!).getTime(),
    sortDirections: ['ascend', 'descend'],
    render: (_, record) => {
      if (record.uploadedAt) {
        return <div>{moment(record.uploadedAt).format('lll')}</div>
      } else {
        return null
      }
    },
  },
]

const ReportUploadListTable = ({
  prefectureCode,
  year,
}: {
  prefectureCode: number
  year: number
}) => {
  const [getReportUploadList, { data: reportUploadListData, called, loading }] =
    useReportUploadListLazyQuery({
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    if (prefectureCode === 0) return

    const variables = {
      input: {
        prefectureCode,
        year,
      },
    }
    getReportUploadList({ variables })
  }, [getReportUploadList, prefectureCode, year])

  if (loading) {
    return <Loading />
  }

  if (
    !reportUploadListData?.reportUploadList ||
    !reportUploadListData.reportUploadList.data
  ) {
    if (called) {
      return <NoData />
    } else {
      return null
    }
  }

  let dataSource = reportUploadListData.reportUploadList.data

  return (
    <Table
      className="w-full overflow-x-scroll"
      columns={tableColumns}
      dataSource={dataSource}
      rowKey="prefectureSchoolCode"
    />
  )
}

const ReportUploadListPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const prefectureCode = 8
  const [year, setYear] = useState(2021)

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={<div className="theme-nav-title">CSVデータアップロード確認</div>}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Card>
        <Select value={year} onChange={(v) => setYear(v)}>
          <Option value={2021}>2021 ({makeYearName(2021)})</Option>
        </Select>

        <ReportUploadListTable prefectureCode={prefectureCode} year={year} />
      </Card>
    </Dashboard>
  )
}

export default ReportUploadListPage
