var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState, createContext } from 'react';
import { auth } from '../services/firebase';
import { useAuthorizeUserMutation } from '../generated/graphql';
var initialState = {
    currentUser: null,
    isAuthChecked: false,
    isTokenChecked: false,
    isRegistered: false,
    setAccessToken: function (x) { },
};
export var AuthContext = createContext(initialState);
export var LOCAL_STORAGE_EMAIL_FOR_SIGN_IN_KEY = 'sign-in-email';
export var LOCAL_STORAGE_AUTH_TOKEN_KEY = 'x-auth-token';
export var signInAnonymously = function () { return __awaiter(void 0, void 0, void 0, function () {
    var err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, auth.signInAnonymously()];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                err_1 = _a.sent();
                console.log(err_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var signIn = function (email, url) { return __awaiter(void 0, void 0, void 0, function () {
    var conf, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                conf = {
                    url: url,
                    handleCodeInApp: true,
                };
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, auth.sendSignInLinkToEmail(email, conf)];
            case 2:
                _a.sent();
                window.localStorage.setItem(LOCAL_STORAGE_EMAIL_FOR_SIGN_IN_KEY, email);
                return [3 /*break*/, 4];
            case 3:
                err_2 = _a.sent();
                console.error(err_2);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var login = function () { return __awaiter(void 0, void 0, void 0, function () {
    var email, err_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!auth.isSignInWithEmailLink(window.location.href)) {
                    return [2 /*return*/];
                }
                email = window.localStorage.getItem(LOCAL_STORAGE_EMAIL_FOR_SIGN_IN_KEY);
                if (!email) {
                    return [2 /*return*/];
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, auth.signInWithEmailLink(email, window.location.href)];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                err_3 = _a.sent();
                console.error(err_3);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var logout = function () { return __awaiter(void 0, void 0, void 0, function () {
    var err_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                window.localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);
                return [4 /*yield*/, auth.signOut()];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                err_4 = _a.sent();
                console.error(err_4);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var AuthProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), currentUser = _b[0], setCurrentUser = _b[1];
    var _c = useState(false), isAuthChecked = _c[0], setIsAuthChecked = _c[1];
    var _d = useState(false), isTokenChecked = _d[0], setIsTokenChecked = _d[1];
    var _e = useState(false), isRegistered = _e[0], setIsRegistered = _e[1];
    var authorizeUser = useAuthorizeUserMutation()[0];
    var setAccessToken = function (token) {
        window.localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, token);
        setIsRegistered(true);
    };
    useEffect(function () {
        auth.onAuthStateChanged(function (user) {
            if (user) {
                setCurrentUser(user);
                authorizeUser({ variables: { uid: user.uid } })
                    .then(function (res) {
                    var _a, _b;
                    if ((_b = (_a = res.data) === null || _a === void 0 ? void 0 : _a.auth) === null || _b === void 0 ? void 0 : _b.token) {
                        var token = res.data.auth.token;
                        setAccessToken(token);
                    }
                })
                    .finally(function () {
                    setIsTokenChecked(true);
                });
            }
            setIsAuthChecked(true);
        });
    }, [authorizeUser]);
    var value = {
        currentUser: currentUser,
        isAuthChecked: isAuthChecked,
        isTokenChecked: isTokenChecked,
        isRegistered: isRegistered,
        setAccessToken: setAccessToken,
    };
    return _jsx(AuthContext.Provider, __assign({ value: value }, { children: children }), void 0);
};
