import { useEffect } from 'react'

import { Dashboard } from '../layout/Dashboard'
import { Card } from '@alpha/components'
import {
  ReportTestRankTargetSummary,
  SchoolCategory,
  useReportTestRankTargetSummaryLazyQuery,
} from '@alpha/core'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'

type ReportTestRankTargetByCategory = {
  schoolCategory: SchoolCategory
  rates: number[]
}

export type ReportTestRankTarget = {
  year: number
  totalRates: number[]
  schoolCategories: ReportTestRankTargetByCategory[]
}

const styleForReport = {
  fontSize: 14,
  width: 64,
}

const TableHeader = () => {
  return (
    <thead>
      <tr className="table-border-yellow">
        <th className="table-border-yellow" rowSpan={2} style={styleForReport}>
          年度
        </th>
        <th className="table-border-yellow" colSpan={4}>
          A+Bの割合
        </th>
        <th className="table-border-yellow" colSpan={3}>
          D+Eの割合
        </th>
      </tr>
      <tr className="table-border-yellow">
        <th className="table-border-yellow" style={styleForReport}>
          全体
        </th>
        <th className="table-border-yellow" style={styleForReport}>
          小学校
        </th>
        <th className="table-border-yellow" style={styleForReport}>
          中学校
        </th>
        <th className="table-border-yellow" style={styleForReport}>
          高校
        </th>
        <th className="table-border-yellow" style={styleForReport}>
          小学校
        </th>
        <th className="table-border-yellow" style={styleForReport}>
          中学校
        </th>
        <th className="table-border-yellow" style={styleForReport}>
          高校
        </th>
      </tr>
    </thead>
  )
}

const makeYearName = (y: number): string => {
  if (y >= 2019) {
    return `R${y - 2018}`
  }
  return `H${y - 1988}`
}

type TableBodyProps = {
  data: ReportTestRankTargetSummary
}

const TableBody = ({ data }: TableBodyProps) => {
  let reports = data.reports?.slice().sort((a, b) => b.year! - a.year!)

  if (!reports) {
    return null
  }

  return (
    <tbody>
      {reports.map((data) => {
        const totalUpperRate = data.totalRates![0] + data.totalRates![1]
        return (
          <tr className="table-border-yellow" key={data.year}>
            <th className="table-border-yellow">
              {/* {data.year === thisYear ? '目標' : makeYearName(data.year!)} */}
              {makeYearName(data.year!)}
            </th>
            <td className="table-border-yellow">
              {totalUpperRate.toFixed(1)}%
            </td>

            <td className="table-border-yellow">
              {(
                data.elementarySchoolRates![0] + data.elementarySchoolRates![1]
              ).toFixed(1)}
              %
            </td>
            <td className="table-border-yellow">
              {(
                data.juniorHighSchoolRates![0] + data.juniorHighSchoolRates![1]
              ).toFixed(1)}
              %
            </td>
            <td className="table-border-yellow">
              {(data.highSchoolRates![0] + data.highSchoolRates![1]).toFixed(1)}
              %
            </td>
            <td className="table-border-yellow">
              {(
                data.elementarySchoolRates![3] + data.elementarySchoolRates![4]
              ).toFixed(1)}
              %
            </td>
            <td className="table-border-yellow">
              {(
                data.juniorHighSchoolRates![3] + data.juniorHighSchoolRates![4]
              ).toFixed(1)}
              %
            </td>
            <td className="table-border-yellow">
              {(data.highSchoolRates![3] + data.highSchoolRates![4]).toFixed(1)}
              %
            </td>
          </tr>
        )
      })}
    </tbody>
  )
}

type TableProps = {
  prefectureCode: number
}

const ReportTestRankTargetTable = ({ prefectureCode }: TableProps) => {
  const [getReport, { data, loading }] =
    useReportTestRankTargetSummaryLazyQuery({
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    if (prefectureCode === 0) return
    getReport({
      variables: {
        input: {
          prefectureCode,
        },
      },
    })
  }, [prefectureCode, getReport])

  if (loading) {
    return <Loading />
  }

  if (!data?.reportTestRankTargetSummary?.data) {
    return <NoData />
  }

  let reportData = data.reportTestRankTargetSummary.data

  return (
    <>
      <table className="table border-collapse">
        <TableHeader />
        <TableBody data={reportData} />
      </table>
    </>
  )
}

const ReportTestRankTargetPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const prefectureCode = 8

  return (
    <Dashboard
      navbar={
        <div className="theme-nav-title">総合評価段階別人数の割合の推移</div>
      }
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="min-h-full print:hidden">
        <Card>
          <div className="pt-4 pb-2">
            <span className="border border-black">目標水準</span>
            「体力テストにおける総合評価Ａ及びＢの割合」
            <br />
            平成２７年度 55.0％ ⇒
            <span className="border border-black">60.0％</span>
            （令和２年度目標値）
          </div>

          <div className="font-bold">
            ＜平成21年度からの（Ａ＋Ｂの割合）及び（Ｄ＋Ｅの割合）の推移＞
          </div>

          <ReportTestRankTargetTable prefectureCode={prefectureCode} />
        </Card>
      </div>
      <div className="hidden print:block">
        <div>茨城県児童生徒の体力・運動能力調査結果</div>
        <div>総合評価段階別人数の割合の推移</div>

        <div className="pt-4 pb-2">
          <span className="border border-black">目標水準</span>
          「体力テストにおける総合評価Ａ及びＢの割合」
          <br />
          平成２７年度 55.0％ ⇒
          <span className="border border-black">60.0％</span>
          （令和２年度目標値）
        </div>

        <div className="font-bold">
          ＜平成21年度からの（Ａ＋Ｂの割合）及び（Ｄ＋Ｅの割合）の推移＞
        </div>

        <ReportTestRankTargetTable prefectureCode={prefectureCode} />
      </div>
    </Dashboard>
  )
}

export default ReportTestRankTargetPage
