var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCitiesLazyQuery } from '@alpha/core';
import { useEffect } from 'react';
import { Select } from 'antd';
var Option = Select.Option;
export var JapanCitySelect = function (_a) {
    var _b, _c;
    var prefectureId = _a.prefectureId, value = _a.value, onChange = _a.onChange;
    var _d = useCitiesLazyQuery({
        fetchPolicy: 'cache-first',
    }), getCities = _d[0], _e = _d[1], cityData = _e.data, loading = _e.loading;
    useEffect(function () {
        if (!prefectureId)
            return;
        getCities({ variables: { input: { prefecture: prefectureId } } });
        onChange('');
    }, [prefectureId, getCities, onChange]);
    if (!prefectureId || loading || !cityData) {
        return null;
    }
    return (_jsxs(Select, __assign({ className: "w-32", value: value, onChange: onChange }, { children: [_jsx(Option, __assign({ value: '' }, { children: "\u5E02\u533A\u753A\u6751" }), void 0),
            _jsx(_Fragment, { children: (_c = (_b = cityData === null || cityData === void 0 ? void 0 : cityData.cities) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.map(function (v) { return (_jsx(Option, __assign({ value: v.name }, { children: v.name }), v.name)); }) }, void 0)] }), void 0));
};
