var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAreasQuery } from '@alpha/core';
import { Select } from 'antd';
var Option = Select.Option;
export var JapanAreaSelect = function (_a) {
    var _b, _c;
    var value = _a.value, onChange = _a.onChange;
    var areaData = useAreasQuery({
        fetchPolicy: 'cache-first',
    }).data;
    return (_jsxs(Select, __assign({ className: "w-32", value: value, onChange: onChange }, { children: [_jsx(Option, __assign({ value: "" }, { children: "\u5730\u57DF" }), void 0),
            _jsx(_Fragment, { children: (_c = (_b = areaData === null || areaData === void 0 ? void 0 : areaData.areas) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.map(function (v) { return (_jsx(Option, __assign({ value: v._id }, { children: v.name }), v._id)); }) }, void 0)] }), void 0));
};
