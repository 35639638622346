import { Card } from '@alpha/components'
import { Dashboard } from '../layout/Dashboard'
import {
  SchoolCategory,
  useReportAverageScoreGraphSummaryLazyQuery,
  UserGender,
} from '@alpha/core'
import ScoreLineChart from '../graph/ScoreLineChart'
import { useEffect, useState } from 'react'
import { Checkbox, Card as ACard } from 'antd'
import Title from 'antd/es/typography/Title'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'

export const scoreTypes = {
  grip: '握力',
  sitUps: '上体起こし',
  bending: '長座体前屈',
  sideJump: '反復横跳び',
  shuttleRun: 'シャトルラン',
  sprintRun: '50m走',
  standingJump: '立ち幅跳び',
  handballThrow: 'ボール投げ',
  totalScore: '得点合計',
}

const schoolCategories = [
  SchoolCategory.ElementarySchool,
  SchoolCategory.JuniorHighSchool,
  SchoolCategory.HighSchool,
]

type ReportAverageScoreByGrade = {
  schoolCategory: SchoolCategory
  grade: number
  score: number
}

type ReportAverageScoreByYear = {
  year: number
  grades: ReportAverageScoreByGrade[]
}

export type ReportAverageScoreByGender = {
  gender: UserGender
  years: ReportAverageScoreByYear[]
}

type ReportAverageScoreByTest = {
  scoreType: string
  genders: ReportAverageScoreByGender[]
}

const tabList = Object.keys(scoreTypes).map((t) => ({
  key: t,
  tab: scoreTypes[t],
}))

const useReportLazyQuery = () => {
  return [
    useReportAverageScoreGraphSummaryLazyQuery({
      fetchPolicy: 'network-only',
    }),
    useReportAverageScoreGraphSummaryLazyQuery({
      fetchPolicy: 'network-only',
    }),
  ]
}

type GraphProps = {
  prefectureCode: number
  schoolCategories: SchoolCategory[]
  forPrinting: boolean
}

const ReportGraph = ({
  prefectureCode,
  schoolCategories,
  forPrinting,
}: GraphProps) => {
  const [
    [getReportForMale, { data: dataForMale, loading: loadingForMale }],
    [getReportForFemale, { data: dataForFemale, loading: loadingForFemale }],
  ] = useReportLazyQuery()
  const [selectedTab, setSelectedTab] = useState('grip')
  const handleChangeTab = (key) => setSelectedTab(key)
  const [dataByTest, setDataByTest] = useState<ReportAverageScoreByTest[]>([])
  const [targetData, setTargetData] = useState<ReportAverageScoreByTest>()

  useEffect(() => {
    if (prefectureCode === 0) return

    getReportForMale({
      variables: {
        input: {
          prefectureCode,
          gender: 1,
        },
      },
    })
    getReportForFemale({
      variables: {
        input: {
          prefectureCode,
          gender: 2,
        },
      },
    })
  }, [prefectureCode, getReportForMale, getReportForFemale])

  useEffect(() => {
    if (!dataForMale || !dataForFemale) return

    const data: ReportAverageScoreByTest[] = []
    Object.keys(scoreTypes).forEach((t) => {
      const genders: ReportAverageScoreByGender[] = [
        {
          gender: UserGender.Male,
          years: [],
        },
        {
          gender: UserGender.Female,
          years: [],
        },
      ]
      const convertMethod = (r, idx, schoolCategory) => {
        if (!r || !r.grades || !r.year) return
        const reportByYear = genders[idx].years.find((y) => y.year === r.year)
        const grades: ReportAverageScoreByGrade[] = reportByYear
          ? reportByYear.grades
          : []
        r.grades.forEach((g) => {
          if (!g || !g.grade || !g.score) return
          grades.push({
            schoolCategory,
            grade: g.grade,
            score: g.score[t],
          })
        })
        if (!reportByYear) {
          genders[idx].years.push({
            year: r.year,
            grades,
          })
        }
      }
      ;[dataForMale, dataForFemale].forEach((d, idx) => {
        d.reportAverageScoreGraphSummary?.data?.elementarySchoolReport?.reports?.forEach(
          (r) => convertMethod(r, idx, SchoolCategory.ElementarySchool),
        )
        d.reportAverageScoreGraphSummary?.data?.juniorHighSchoolReport?.reports?.forEach(
          (r) => convertMethod(r, idx, SchoolCategory.JuniorHighSchool),
        )
        d.reportAverageScoreGraphSummary?.data?.highSchoolReport?.reports?.forEach(
          (r) => convertMethod(r, idx, SchoolCategory.HighSchool),
        )
      })
      if (genders[0].years.length === 0 && genders[1].years.length === 0) return
      data.push({
        scoreType: t,
        genders: genders,
      })
    })
    setDataByTest(data)
  }, [dataForMale, dataForFemale, setDataByTest])

  useEffect(() => {
    const targetData = dataByTest.find((t) => t.scoreType === selectedTab)
    setTargetData(targetData)
  }, [selectedTab, dataByTest, setTargetData])

  if (loadingForMale || loadingForFemale) {
    return <Loading />
  }

  if (!dataForMale || !dataForFemale) {
    return null
  }

  if (dataByTest.length === 0) {
    return <NoData />
  }

  if (forPrinting) {
    return (
      <>
        {dataByTest.map((data) => (
          <>
            {data.genders.map((g) => {
              const genderName = g.gender === UserGender.Male ? '男子' : '女子'
              return (
                <ACard
                  title={scoreTypes[data.scoreType]}
                  style={{ pageBreakAfter: 'always' }}
                >
                  <ACard.Grid
                    style={{ width: '100%', textAlign: 'center' }}
                    key={g.gender}
                    hoverable={false}
                  >
                    <Title level={5} style={{ marginBottom: 36 }}>
                      {genderName}
                    </Title>
                    <ScoreLineChart
                      data={g}
                      visibleCategories={schoolCategories}
                      forPrinting
                      scoreType={data.scoreType}
                    />
                  </ACard.Grid>
                </ACard>
              )
            })}
          </>
        ))}
      </>
    )
  }

  return (
    <ACard
      style={{ width: '100%' }}
      tabList={tabList}
      activeTabKey={selectedTab}
      onTabChange={handleChangeTab}
    >
      {targetData?.genders.map((g) => {
        const genderName = g.gender === UserGender.Male ? '男子' : '女子'
        return (
          <ACard.Grid
            style={{ width: '50%', textAlign: 'center' }}
            key={g.gender}
            hoverable={false}
          >
            <Title level={5} style={{ marginBottom: 24 }}>
              {genderName}
            </Title>
            <ScoreLineChart
              data={g}
              visibleCategories={schoolCategories}
              forPrinting={false}
              scoreType={targetData.scoreType}
            />
          </ACard.Grid>
        )
      })}
    </ACard>
  )
}

const ReportAverageScoreGraphPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const prefectureCode = 8

  const [checkedSchoolCategories, setCheckedSchoolCategories] = useState(
    schoolCategories,
  )
  const handleSchoolCategories = (v) => {
    setCheckedSchoolCategories(v)
  }

  return (
    <Dashboard
      navbar={<div className="theme-nav-title">測定項目平均値の年次推移</div>}
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="print:hidden">
        <Card>
          <div className="pb-8 space-y-4">
            <Checkbox.Group
              value={checkedSchoolCategories}
              onChange={handleSchoolCategories}
            >
              <Checkbox
                value={SchoolCategory.ElementarySchool}
                className="dark:text-white"
              >
                小学校
              </Checkbox>
              <Checkbox
                value={SchoolCategory.JuniorHighSchool}
                className="dark:text-white"
              >
                中学校
              </Checkbox>
              <Checkbox
                value={SchoolCategory.HighSchool}
                className="dark:text-white"
              >
                高等学校
              </Checkbox>
            </Checkbox.Group>
          </div>

          <ReportGraph
            prefectureCode={prefectureCode}
            schoolCategories={checkedSchoolCategories}
            forPrinting={false}
          />
        </Card>
      </div>
      <div className="hidden print:block">
        <div>茨城県児童生徒の体力・運動能力調査結果</div>
        <div>測定項目平均値の年次推移</div>
        <ReportGraph
          prefectureCode={prefectureCode}
          schoolCategories={checkedSchoolCategories}
          forPrinting
        />
      </div>
    </Dashboard>
  )
}

export default ReportAverageScoreGraphPage
